import * as React from 'react';
import {Fragment, useState} from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Drawer from '@mui/joy/Drawer';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Checkbox from '@mui/joy/Checkbox';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import ModalClose from '@mui/joy/ModalClose';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import Stack from '@mui/joy/Stack';
import RadioGroup from '@mui/joy/RadioGroup';
import Radio from '@mui/joy/Radio';
import Sheet from '@mui/joy/Sheet';
import Switch from '@mui/joy/Switch';
import Typography from '@mui/joy/Typography';
import TuneIcon from '@mui/icons-material/TuneRounded';
import Done from '@mui/icons-material/Done';
import {AllInclusive, ArrowDropDown, ArrowDropUp, School, SupervisedUserCircle} from "@mui/icons-material";
import {Slider} from "@mui/joy";
import IconButton from "@mui/joy/IconButton";

export const DrawerFilters = ({
                                  courseType,
                                  setCourseType,
                                  qualification,
                                  setQualification,
                                  days,
                                  setDays,
                                  dayParts,
                                  setDayParts,
                                  maxDistance, setMaxDistance,
                                  accountPreferences, setAccountPreferences,
                                  search,
                                  resultCount,
                                  setSort, sortType, setSortType, sort
                              }) => {
    const [open, setOpen] = useState(false);
    return (
        <Fragment>
            <IconButton
                variant="outlined"
                color="neutral"
                sx={{
                    display: {xs: open ? "none" : "flex", md: "none"},
                    position: "fixed",
                    right: 25,
                    bottom: 75,
                    zIndex: 99999,
                    width: 50,
                    height: 50,
                    borderRadius: "50%",
                    backgroundColor: "#FFF"
                }}
                onClick={() => setOpen(true)}
            >
                <TuneIcon/>
            </IconButton>
            <Card orientation={"horizontal"} justifyContent={"center"}
                  sx={{
                      width: {xs: "90%", md: "70%"},
                      display: {xs: "none", md: "flex"},
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      p: 0,
                      pt: 1,
                      pb: 1
                  }}>
                <Typography fontWeight={sort === "date" ? "bold" : "normal"} onClick={() => {
                    setSort("date")
                    setSortType(sortType === "asc" ? "desc" : "asc")
                }} sx={{cursor: "pointer", width: 120}}
                            endDecorator={sort === "date" && sortType === "asc" ? <ArrowDropUp/> :
                                <ArrowDropDown/>}>Datum</Typography>


<Typography fontWeight={sort === "payment_multiplier" ? "bold" : "normal"} onClick={() => {
                    setSort("payment_multiplier")
                    setSortType(sortType === "asc" ? "desc" : "asc")
                }} sx={{cursor: "pointer", width: 120}}
                            endDecorator={sort === "payment_multiplier" && sortType === "asc" ? <ArrowDropUp/> :
                                <ArrowDropDown/>}>Vergoeding</Typography>

<Typography fontWeight={sort === "application_status" ? "bold" : "normal"} onClick={() => {
                    setSort("application_status")
                    setSortType(sortType === "asc" ? "desc" : "asc")
                }} sx={{cursor: "pointer", width: 120}}
                            endDecorator={sort === "application_status" && sortType === "asc" ? <ArrowDropUp/> :
                                <ArrowDropDown/>}>Aangemeld</Typography>


               


                <Typography fontWeight={sort === "school" ? "bold" : "normal"} onClick={() => {
                    setSort("school")
                    setSortType(sortType === "asc" ? "desc" : "asc")
                }} sx={{cursor: "pointer", width: 120}}
                            endDecorator={sort === "school" && sortType === "asc" ? <ArrowDropUp/> :
                                <ArrowDropDown/>}>School</Typography>
                <Button
                    variant="outlined"
                    color="neutral"
                    startDecorator={<TuneIcon/>}
                    onClick={() => setOpen(true)}
                >
                    Filter
                </Button>
            </Card>
            <Drawer
                size="md"
                variant="plain"
                open={open}
                onClose={() => setOpen(false)}
                slotProps={{
                    content: {
                        sx: {
                            bgcolor: 'transparent',
                            p: {md: 3, sm: 0},
                            boxShadow: 'none',
                        },
                    },
                }}
            >
                <Sheet
                    sx={{
                        borderRadius: 'md',
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        height: '100%',
                        overflow: 'auto',
                    }}
                >
                    <DialogTitle>Filters</DialogTitle>
                    <ModalClose/>
                    <Divider sx={{mt: 'auto'}}/>
                    <DialogContent sx={{gap: 2}}>
                        <FormControl>
                            <FormLabel sx={{typography: 'title-md', fontWeight: 'bold'}}>
                                Opdracht keuze
                            </FormLabel>
                            <RadioGroup
                                value={courseType || ''}
                                onChange={(event) => {
                                    setCourseType(event.target.value);
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',
                                        gap: 1.5,
                                    }}
                                >
                                    {[{
                                        name: 'Alles',
                                        key: "alles",
                                        icon: <AllInclusive/>,
                                    },
                                        {
                                            name: 'Lesgeven',
                                            key: 'teach',
                                            icon: <School/>,
                                        },
                                        {
                                            name: 'Surveilleren',
                                            key: "surveillance",
                                            icon: <SupervisedUserCircle/>,
                                        }
                                        // ,
                                        // {
                                        //     name: 'Schrijven',
                                        //     key: "write",
                                        //     icon: <Create/>,
                                        // }
                                    ].map((item) => (
                                        <Card
                                            key={item.key}
                                            sx={{
                                                boxShadow: 'none',
                                                '&:hover': {bgcolor: 'background.level1'},
                                            }}
                                        >
                                            <CardContent>
                                                {item.icon}
                                                <Typography level="title-md">{item.name}</Typography>
                                            </CardContent>
                                            <Radio
                                                disableIcon
                                                overlay
                                                checked={courseType === item.key}
                                                variant="outlined"
                                                color="neutral"
                                                value={item.key}
                                                sx={{mt: -2}}
                                                slotProps={{
                                                    action: {
                                                        sx: {
                                                            ...(courseType === item.key && {
                                                                borderWidth: 2,
                                                                borderColor:
                                                                    'var(--joy-palette-primary-outlinedBorder)',
                                                            }),
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        },
                                                    },
                                                }}
                                            />
                                        </Card>
                                    ))}
                                </Box>
                            </RadioGroup>
                        </FormControl>
                        <FormControl>
                            <FormLabel sx={{typography: 'title-md', fontWeight: 'bold'}}>
                                Kwalificaties
                            </FormLabel>
                            <RadioGroup
                                value={courseType || ''}
                                onChange={(event) => {
                                    setQualification(event.target.value)
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',
                                        gap: 1.5,
                                    }}
                                >
                                    {[
                                        {
                                            name: 'Alles',
                                            key: "alles",
                                            icon: <AllInclusive/>,
                                        },
                                        {
                                            name: 'Eerste graads',
                                            key: 'eerste-graads',
                                            icon: <School/>,
                                        },
                                        {
                                            name: 'Tweede graads',
                                            key: "tweede-graads",
                                            icon: <School/>,
                                        },
                                        {
                                            name: 'Basisschool',
                                            key: "basisonderwijs",
                                            icon: <School/>,
                                        }
                                    ].map((item) => (
                                        <Card
                                            key={item.key}
                                            sx={{
                                                boxShadow: 'none',
                                                '&:hover': {bgcolor: 'background.level1'},
                                            }}
                                        >
                                            <CardContent>
                                                {item.icon}
                                                <Typography level="title-md">{item.name}</Typography>
                                            </CardContent>
                                            <Radio
                                                disableIcon
                                                overlay
                                                checked={qualification === item.key}
                                                variant="outlined"
                                                color="neutral"
                                                value={item.key}
                                                sx={{mt: -2}}
                                                slotProps={{
                                                    action: {
                                                        sx: {
                                                            ...(qualification === item.key && {
                                                                borderWidth: 2,
                                                                borderColor:
                                                                    'var(--joy-palette-primary-outlinedBorder)',
                                                            }),
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        },
                                                    },
                                                }}
                                            />
                                        </Card>
                                    ))}
                                </Box>
                            </RadioGroup>
                        </FormControl>
                        <Typography level="title-md" fontWeight="bold">
                            Dagen
                        </Typography>
                        <div role="group" aria-labelledby="rank">
                            <List
                                orientation="horizontal"
                                size="sm"
                                sx={{
                                    '--List-gap': '6px',
                                    '--ListItem-radius': '20px',
                                    flexWrap: "wrap"
                                }}
                            >
                                {['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag'].map((item, index) => {
                                    const selected = days.includes(index);
                                    return (
                                        <ListItem key={item} sx={{margin: 1}}>
                                            <AspectRatio
                                                variant={selected ? 'solid' : 'outlined'}
                                                color={selected ? 'primary' : 'neutral'}
                                                ratio={1}
                                                sx={{width: 20, borderRadius: 20, ml: -0.5, mr: 0.75}}
                                            >
                                                <div>{selected && <Done fontSize="md"/>}</div>
                                            </AspectRatio>
                                            <Checkbox
                                                size="sm"
                                                color="neutral"
                                                disableIcon
                                                overlay
                                                label={item}
                                                variant="outlined"
                                                checked={selected}
                                                onChange={(event) =>
                                                    setDays((prev) => {
                                                        const set = new Set([...prev, index]);
                                                        if (!event.target.checked) {
                                                            set.delete(index);
                                                        }

                                                        return [...set];
                                                    })
                                                }
                                                slotProps={{
                                                    action: {
                                                        sx: {
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        },
                                                    },
                                                }}
                                            />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </div>
                        <Typography level="title-md" fontWeight="bold">
                            Dagdelen
                        </Typography>
                        <div role="group" aria-labelledby="rank">
                            <List
                                orientation="horizontal"
                                size="sm"
                                sx={{
                                    '--List-gap': '6px',
                                    '--ListItem-radius': '20px',
                                    flexWrap: "wrap"
                                }}
                            >
                                {['Ochtend', "Middag", "Avond"].map((item, index) => {
                                    const selected = dayParts.includes(index);
                                    return (
                                        <ListItem key={item} sx={{margin: 1}}>
                                            <AspectRatio
                                                variant={selected ? 'solid' : 'outlined'}
                                                color={selected ? 'primary' : 'neutral'}
                                                ratio={1}
                                                sx={{width: 20, borderRadius: 20, ml: -0.5, mr: 0.75}}
                                            >
                                                <div>{selected && <Done fontSize="md"/>}</div>
                                            </AspectRatio>
                                            <Checkbox
                                                size="sm"
                                                color="neutral"
                                                disableIcon
                                                overlay
                                                label={item}
                                                variant="outlined"
                                                checked={selected}
                                                onChange={(event) =>
                                                    setDayParts((prev) => {
                                                        const set = new Set([...prev, index]);
                                                        if (!event.target.checked) {
                                                            set.delete(index);
                                                        }

                                                        return [...set];
                                                    })
                                                }
                                                slotProps={{
                                                    action: {
                                                        sx: {
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        },
                                                    },
                                                }}
                                            />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </div>

                        {/*<Typography level="title-md" fontWeight="bold">*/}
                        {/*    Afstand*/}
                        {/*</Typography>*/}
                        {/*<FormControl orientation="horizontal" sx={{pr: 1, pl: 1}}>*/}
                        {/*    <Box sx={{flex: 1, pr: 1, pl: 1}}>*/}
                        {/*        <Slider marks={[{value: 0, label: "0km"}, {value: 25, label: "25km"}, {*/}
                        {/*            value: 50,*/}
                        {/*            label: "50km"*/}
                        {/*        }]} value={maxDistance} sx={{width: "80%"}}*/}
                        {/*                onChange={(e) => setMaxDistance(e.target.value)} step={5}*/}
                        {/*                min={0} max={50} valueLabelFormat={(v, i) => v.toString() + "km"}*/}
                        {/*                valueLabelDisplay="auto" getAriaValueText={(value) => value.toString() + "km"}*/}
                        {/*        />*/}
                        {/*    </Box>*/}
                        {/*</FormControl>*/}
                        <Typography level="title-md" fontWeight="bold">
                            Vak opties
                        </Typography>
                        <FormControl orientation="horizontal">
                            <Box sx={{flex: 1, pr: 1}}>
                                <FormLabel sx={{typography: 'title-sm'}}>
                                    Voorkeuren
                                </FormLabel>
                                <FormHelperText sx={{typography: 'body-sm'}}>
                                    Rekening houden met mijn vak voorkeuren
                                </FormHelperText>
                            </Box>
                            <Switch value={accountPreferences}
                                    onChange={(e) => setAccountPreferences(e.target.checked)}/>
                        </FormControl>
                    </DialogContent>

                    <Divider sx={{mt: 'auto'}}/>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        useFlexGap
                        spacing={1}
                    >
                        <Button
                            variant="outlined"
                            color="neutral"
                            onClick={() => {
                                setCourseType('');
                                setDays([0, 1, 2, 3, 4]);
                                setDayParts([0, 1, 2]);
                                setQualification('');
                                setMaxDistance(50)
                            }}
                        >
                            Leegmaken
                        </Button>
                        <Button onClick={() => {
                            setOpen(false)
                            search()
                        }}>{resultCount} resultaten</Button>
                    </Stack>
                </Sheet>
            </Drawer>
        </Fragment>
    );
}
