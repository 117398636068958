import {AspectRatio, Avatar, Button, Card, CardContent, Chip, Typography} from "@mui/joy";
import {CalendarMonth, Check, Close, EventAvailable, EventBusy, HourglassBottom, Money} from "@mui/icons-material";
import * as React from "react";
import {useEffect, useState} from "react";
import Box from "@mui/joy/Box";
import {timeUntil} from "../utils/utils";

export const CourseCard = ({course, small = false, large = false, variant = "teacher"}) => {
    const [truncateLength, setTruncateLength] = useState(160);
    useEffect(() => {
        const handleResize = () => {
            let newTruncateLength = 60;
            if (window.innerWidth > 700) {
                newTruncateLength = 90
            }
            if (window.innerWidth > 900) {
                newTruncateLength = 160
            }
            if (window.innerWidth > 1200) {
                newTruncateLength = 340
            }
            if (window.innerWidth > 1400) {
                newTruncateLength = 440
            }
            setTruncateLength(newTruncateLength);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const timeLeft = timeUntil(course.date)

    return (
        <Card orientation={truncateLength > 120 ? "horizontal" : "vertical"}
              sx={{width: {xs: large ? "100%" : '90%', md: small ? "49%" : large ? "100%" : '70%'}, padding: 0}}>
            <AspectRatio ratio="1" maxHeight={truncateLength <= 120 ? 150 : "100%"}
                         sx={{
                             width: truncateLength > 120 ? 250 : "100%",
                             minHeight: truncateLength > 120 ? "100%" : ""
                         }}
                         objectFit={"cover"}>
                <img
                    src={course.school?.school_picture}
                    loading="lazy"
                    alt=""
                />
            </AspectRatio>
            <CardContent style={{padding: 10}}>
                <div className={"chip-container"}>
                    <div style={{display: "flex", gap: 5}}>
                        <Chip style={{alignSelf: "flex-start"}} variant={"outlined"}
                              startDecorator={
                                  <CalendarMonth/>}>{new Date(course.date).toLocaleString("nl-NL", {
                            day: '2-digit',
                            month: '2-digit',
                            year: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit'
                        })}</Chip>
                        {timeLeft && <Chip style={{alignSelf: "flex-start"}} variant={"outlined"}
                                           startDecorator={
                                               <CalendarMonth/>}>{timeLeft}</Chip>}
                    </div>
                    {truncateLength === 120 && variant === "teacher" && <><Chip variant={"outlined"}
                                                                                startDecorator={course.status === "open" ?
                                                                                    <EventAvailable/> :
                                                                                    course.status === "wachtrij" ?
                                                                                        <HourglassBottom/> :
                                                                                        <EventBusy/>
                                                                                }
                                                                                color={course.status === "open" ? "success" : course.status === "wachtrij" ? "warning" : "danger"}>{course.status}</Chip>

                        <Chip variant={"outlined"} color={"success"}
                              startDecorator={<Money/>}>x{course.payment_multiplier}</Chip>

                        <Chip variant={"outlined"} color={course.applied ? "success" : "neutral"}
                              startDecorator={course.applied ? <Check/> :
                                  <Close/>}>{course.applied ? "Aangemeld" : "Niet aangemeld"}</Chip></>}
                    {variant === "administrator" && !!course.teacher &&
                        <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
                            <div>
                                <Typography level="body-xs" textAlign={"right"}>{course.teacher.name}</Typography>
                                <Typography level="body-xs" textAlign={"right"}>{course.teacher.email}</Typography>
                            </div>
                            <Avatar size="sm" src={course.teacher.profile_picture}></Avatar>

                        </Box>}

                    {truncateLength > 120 && variant === "teacher" &&
                        <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 10}}>
                            <Chip variant={"outlined"}
                                  startDecorator={course.status === "open" ?
                                      <EventAvailable/> :
                                      course.status === "wachtrij" ? <HourglassBottom/> : <EventBusy/>
                                  }
                                  color={course.status === "open" ? "success" : course.status === "wachtrij" ? "warning" : "danger"}>{course.status}</Chip>

                            <Chip variant={"outlined"} color={"success"}
                                  startDecorator={<Money/>}>x{course.payment_multiplier}</Chip>

                            <Chip variant={"outlined"} color={course.applied ? "success" : "neutral"}
                                  startDecorator={course.applied ? <Check/> :
                                      <Close/>}>{course.applied ? "Aangemeld" : "Niet aangemeld"}</Chip>
                        </div>}
                </div>
                <Typography level="body-sm" fontWeight="lg"
                            textColor="text.tertiary">{course?.school?.name}</Typography>
                <Typography level={"h4"}>{course.name} - {course.class_name}</Typography>
                {!small && <Typography title={course.description} level={"body-sm"}
                                       className={"course-description"}>
                    {course.description && course.description.length > truncateLength ? course.description.substring(0, truncateLength) + "..." : course.description}
                </Typography>}
                <div style={{display: "flex", flexDirection: "row", gap: 10, alignSelf: "flex-end"}}>
                    {course.applied && !window.location.href.includes("uitnodiging") &&
                        <Button variant={"outlined"} onClick={() => {
                            window.location.href = "/uitnodiging/" + course.id
                        }}
                                sx={{
                                    width: 100,
                                    alignSelf: "flex-end",
                                    marginTop: "10px"
                                }}>Status</Button>}
                    <Button variant={"solid"} onClick={() => {
                        if (variant === "administrator") {
                            window.location.href = "/dashboard/courses/create/" + course.id
                        } else
                        window.location.href = "/les/" + course.id
                        // setError("Test error message")   
                    }}
                            sx={{width: 100, alignSelf: "flex-end", marginTop: "10px"}}>Bekijken</Button>
                </div>
            </CardContent>
        </Card>)
}
