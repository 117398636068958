import * as React from 'react';
import {useEffect, useState} from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import LinearProgress from '@mui/joy/LinearProgress';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, {listItemButtonClasses} from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {Link, useLocation} from "react-router-dom";
import {SchoolRounded} from "@mui/icons-material";
import {getFilledCourses} from "../../actions/statistic-calls";


export function openSidebar() {
    if (typeof window !== 'undefined') {
        document.body.style.overflow = 'hidden';
        document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
    }
}

export function closeSidebar() {
    if (typeof window !== 'undefined') {
        document.documentElement.style.removeProperty('--SideNavigation-slideIn');
        document.body.style.removeProperty('overflow');
    }
}

export function toggleSidebar() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const slideIn = window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--SideNavigation-slideIn');
        if (slideIn) {
            closeSidebar();
        } else {
            openSidebar();
        }
    }
}

const Toggler = ({
                     defaultExpanded = true,
                     renderToggle,
                     children,
                 }) => {
    const [open, setOpen] = useState(defaultExpanded);
    return (
        <React.Fragment>
            {renderToggle({open, setOpen})}
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows: open ? '1fr' : '0fr',
                    transition: '0.2s ease',
                    '& > *': {
                        overflow: 'hidden',
                    },
                }}
            >
                {children}
            </Box>
        </React.Fragment>
    );
}

export default function Sidebar({user}) {

    const location = useLocation();
    const currentPath = location.pathname;
    const [filledCourses, setFilledCourses] = useState(80)
    useEffect(() => {
        getFilledCourses(setFilledCourses)
    }, []);
    console.log(process.env.REACT_APP_LOGO)
    console.log(process.env.REACT_APP_AWS_ACCESS_KEY_ID)

    return (
        <Sheet
            className="Sidebar"
            sx={{
                position: {xs: 'fixed', md: 'fixed'},
                transform: {
                    xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                    md: 'none',
                },
                transition: 'transform 0.4s, width 0.4s',
                zIndex: 10000,
                height: '100vh',
                width: 'var(--Sidebar-width)',
                top: 0,
                p: 2,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRight: '1px solid',
                borderColor: 'divider',
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                    ':root': {
                        '--Sidebar-width': '240px',
                        [theme.breakpoints.up('lg')]: {
                            '--Sidebar-width': '240px',
                        },
                    },
                })}
            />
            <Box
                className="Sidebar-overlay"
                sx={{
                    position: 'fixed',
                    zIndex: 9998,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    opacity: 'var(--SideNavigation-slideIn)',
                    backgroundColor: 'var(--joy-palette-background-backdrop)',
                    transition: 'opacity 0.4s',
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                        lg: 'translateX(-100%)',
                    },
                }}
                onClick={() => closeSidebar()}
            />
            <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                {/*<Typography level="title-lg">Delphi</Typography>*/}
                <img onClick={() => {
                    window.location.href = "/dashboard"
                }} src={process.env.REACT_APP_LOGO} style={{width: 180, cursor: "pointer"}}/>
            </Box>
            <Box
                sx={{
                    minHeight: 0,
                    overflow: 'hidden auto',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    [`& .${listItemButtonClasses.root}`]: {
                        gap: 1.5,
                    },
                }}
            >
                <List
                    size="sm"
                    sx={{
                        gap: 1,
                        '--List-nestedInsetStart': '30px',
                        '--ListItem-radius': (theme) => theme.vars.radius.sm,
                    }}
                >
                    <ListItem>
                        <ListItemButton selected={currentPath.endsWith("/dashboard")}
                                        role="menuitem"
                                        component={Link}
                                        to="/dashboard">
                            <DashboardRoundedIcon/>
                            <ListItemContent>
                                <Typography level="title-sm">Dashboard</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>

                    <ListItem nested>
                        <Toggler
                            renderToggle={({open, setOpen}) => (
                                <ListItemButton onClick={() => setOpen(!open)}>
                                    <SchoolRounded/>
                                    <ListItemContent>
                                        <Typography level="title-sm">Lessen</Typography>
                                    </ListItemContent>
                                    <KeyboardArrowDownIcon
                                        sx={{transform: open ? 'rotate(180deg)' : 'none'}}
                                    />
                                </ListItemButton>
                            )}
                        >
                            <List sx={{gap: 0.5}}>
                                <ListItem sx={{mt: 0.5}}>
                                    <ListItemButton selected={currentPath.includes("/dashboard/courses/overview")}
                                                    role="menuitem"
                                                    component={Link}
                                                    to="/dashboard/courses/overview"
                                    >
                                        Lessen overzicht
                                    </ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton selected={currentPath.includes("/dashboard/courses/create")}
                                                    role="menuitem"
                                                    component={Link}
                                                    className="pop-button"
                                                    to="/dashboard/courses/create"
                                    >
                                        Maak een nieuwe les
                                    </ListItemButton>
                                </ListItem>
                                <ListItem sx={{mt: 0.5}}>
                                    <ListItemButton selected={currentPath.includes("/dashboard/courses/applicants")}
                                                    role="menuitem"
                                                    component={Link}
                                                    to={"/dashboard/courses/applicants"}
                                    >
                                        Aanmeldingen
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Toggler>
                        {/*<ListItemButton selected={currentPath.includes("/dashboard/courses/overview")}*/}
                        {/*                role="menuitem"*/}
                        {/*                component={Link}*/}
                        {/*                to="/dashboard/courses/overview">*/}
                        {/*    <SchoolRounded/>*/}
                        {/*    <ListItemContent>*/}
                        {/*        <Typography level="title-sm">Lessen</Typography>*/}
                        {/*    </ListItemContent>*/}
                        {/*</ListItemButton>*/}
                    </ListItem>

                    <ListItem nested>
                        <Toggler
                            renderToggle={({open, setOpen}) => (
                                <ListItemButton onClick={() => setOpen(!open)}>
                                    <GroupRoundedIcon/>
                                    <ListItemContent>
                                        <Typography level="title-sm">Gebruikers</Typography>
                                    </ListItemContent>
                                    <KeyboardArrowDownIcon
                                        sx={{transform: open ? 'rotate(180deg)' : 'none'}}
                                    />
                                </ListItemButton>
                            )}
                        >
                            <List sx={{gap: 0.5}}>
                                <ListItem sx={{mt: 0.5}}>
                                    <ListItemButton selected={currentPath.includes("/dashboard/users/overview")}
                                                    role="menuitem"
                                                    component={Link}
                                                    to="/dashboard/users/overview"
                                    >
                                        Gebruikersoverzicht
                                    </ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton selected={currentPath.includes("/dashboard/users/create")}
                                                    role="menuitem"
                                                    component={Link}
                                                    to="/dashboard/users/create"
                                    >Maak een nieuwe gebruiker</ListItemButton>
                                </ListItem>
                            </List>
                        </Toggler>
                    </ListItem>
                </List>

                <List
                    size="sm"
                    sx={{
                        mt: 'auto',
                        flexGrow: 0,
                        '--ListItem-radius': (theme) => theme.vars.radius.sm,
                        '--List-gap': '8px',
                        mb: 2,
                    }}
                >
                    {/*<ListItem>*/}
                    {/*    <ListItemButton>*/}
                    {/*        <SupportRoundedIcon/>*/}
                    {/*        Support*/}
                    {/*    </ListItemButton>*/}
                    {/*</ListItem>*/}
                    {/*<ListItem>*/}
                    {/*    <ListItemButton>*/}
                    {/*        <SettingsRoundedIcon/>*/}
                    {/*        Settings*/}
                    {/*    </ListItemButton>*/}
                    {/*</ListItem>*/}
                </List>
                <Card
                    invertedColors
                    variant="soft"
                    color="warning"
                    size="sm"
                    sx={{boxShadow: 'none'}}
                >
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography level="title-sm">Gevulde lessen</Typography>
                        <IconButton size="sm">
                            {/*<CloseRoundedIcon/>*/}
                        </IconButton>
                    </Stack>
                    <Typography level="body-xs">
                        Van de openstaande lessen is er {filledCourses}% gevuld.
                    </Typography>
                    <LinearProgress variant="outlined" value={filledCourses} determinate sx={{my: 1}}/>
                    <Button size="sm" variant="solid" component={Link} to={"dashboard/courses/applicants"}>
                        Bekijk open lessen
                    </Button>
                </Card>
            </Box>
            <Divider/>
            <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                <Avatar
                    variant="outlined"
                    size="sm"
                    src={user.profile_picture}
                />
                <Box sx={{minWidth: 0, flex: 1}}>
                    <Typography level="title-sm">{user.firstname} {user.lastname}</Typography>
                    <Typography level="body-xs">{user.email}</Typography>
                </Box>
                <IconButton size="sm" variant="plain" color="neutral" onClick={() => {
                    localStorage.removeItem("userToken")
                    window.location.href = "/login"
                }}>
                    <LogoutRoundedIcon/>
                </IconButton>
            </Box>
        </Sheet>
    );
}
