import {Button, CssBaseline, FormControl, FormLabel, Input, Link, Sheet, Typography} from "@mui/joy";
import {useState} from "react";
import {handleLogin} from "../../actions/login-calls";
import {ErrorToast} from "../utils/alerts";

export const Login = () => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState()
    return (
        <main style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"

        }}>
            <div>
                <video style={{
                    position: "fixed", width: "100vw", height: "100vh", backgroundSize: "cover", left: 0, top: 0,
                    filter: "blur(2px) brightness(0.6)",
                    backgroundRepeat: "no-repeat",
                    objectFit: "cover",
                    transform: "scale(1.01)"
                }} playsInline={true} muted={true} autoPlay={true} loop={true}>
                    <source src={"/cvog.mp4"}/>
                </video>
            </div>
            <CssBaseline/>
            <Sheet
                sx={{
                    width: 300,
                    mx: 'auto',
                    my: "auto",
                    py: 4,
                    px: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 'sm',
                    boxShadow: 'xl',
                }}
                variant="outlined"
            >
                <div>
                    <Typography level="h4" sx={{color: "#272D2D !important"}} component="h1">
                        <b>Welkom bij Delphi!</b>
                    </Typography>
                    <Typography level="body-sm">Log in om door te gaan.</Typography>
                </div>
                <form onSubmit={async (e) => {
                    e.preventDefault()
                    const {token, role} = await handleLogin(username, password)
                    if (token === "error") {
                        setError("Foutieve inloggegevens")
                    } else {
                        localStorage.setItem('userToken', token)
                        let search = new URLSearchParams(window.location.search)
                        if (role && role === "Administrator")
                            window.location.href = "/dashboard"
                        else if (search.has("ref")) {
                            window.location.href = search.get("ref")
                        } else {
                            window.location.href = "/overzicht"
                        }
                    }
                }
                }>
                    <FormControl>
                        <FormLabel>E-mail</FormLabel>
                        <Input value={username}
                               onChange={(e) => setUsername(e.target.value)}
                               name="email"
                               placeholder="johndoe@email.com"
                               color={error && "danger"}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Wachtwoord</FormLabel>
                        <Input
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            name="password"
                            type="password"
                            placeholder="password"
                            color={error && "danger"}
                        />
                    </FormControl>
                    <Button type={"submit"} sx={{mt: 1}}>Log in</Button>
                </form>
                <Typography
                    endDecorator={<Link href="/sign-up">Registreren</Link>}
                    fontSize="sm"
                    sx={{alignSelf: 'center'}}
                >
                    Geen account?
                </Typography>
            </Sheet>
            <ErrorToast error={error} setError={setError}/>
        </main>
    )
        ;
}
