import './home.css'
import {Button, Divider, Typography} from "@mui/joy";
import {useEffect, useState} from "react";
import {getCourses, getRecommendedCourses} from "../../actions/course-calls";
import {CourseCard} from "../cards/CourseCard";
import {GoalCard} from "../cards/GoalCard";
import {getGoals} from "../../actions/goal-calls";
import {getOpenApplications} from "../../actions/applicant-calls";

export const Home = () => {
    const [courses, setCourses] = useState([])
    const [goals, setGoals] = useState()
    const [openApplications, setOpenApplications] = useState(0)
    useState()
    useEffect(() => {
        getRecommendedCourses(setCourses)
        // getGoals(setGoals)
        getOpenApplications(setOpenApplications)
    }, []);
    return (<div className={"home-container"}>
        {/*<Typography level={"h1"} style={{color: "#272D2D"}}>Jouw doelen</Typography>*/}
        {/*<div className={"goal-container"}>*/}

        {/*    <GoalCard amount={openApplications} goalAmountFilled={100} variant={"applicants"}/>*/}
        {/*    {goals && goals.map(goal => {*/}
        {/*        let amountFilled = goal.amount * 100 / goal.goal*/}
        {/*        if (amountFilled > 100) {*/}
        {/*            amountFilled = 100*/}
        {/*        }*/}
        {/*        return <GoalCard variant={goal.type} goalAmountFilled={amountFilled}*/}
        {/*                         amount={goal.amount} goal={goal.goal} rawGoal={goal}*/}
        {/*                         updateGoal={() => {*/}
        {/*                             getGoals(setGoals)*/}
        {/*                         }}*/}
        {/*        />*/}

        {/*    })}*/}
        {/*</div>*/}
        {/*<Divider/>*/}
        <div className={"courses-container"}>
            <Typography level={"h1"} style={{color: "#272D2D"}}>Aanbevolen voor jou</Typography>
            {courses.map((course) => {
                return <CourseCard course={course}/>
            })}
            {courses.length === 0 && <>
                <Typography level={"body-md"}>Er zijn momenteel geen aanbevolen lessen</Typography>
                <Button onClick={() => window.location.href = "/profiel/applications"}>Bekijk je
                        aanmeldingen</Button></>}
                
        </div>

    </div>)
}
