import './App.css';
import {CssVarsProvider, extendTheme, Snackbar} from "@mui/joy";
import {Login} from "./components/auth/Login";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Home} from "./components/home/Home";
import {MobileNavBar} from "./components/nav/MobileNavBar";
import {Schools} from "./components/schools/Schools";
import {Courses} from "./components/classes/Courses";
import {Profile} from "./components/profile/Profile";
import {School} from "./components/schools/School";
import {Course} from "./components/classes/Course";
import {useEffect, useState} from "react";
import {getUser} from "./actions/profile-calls";
import {Dashboard} from "./components/dashboard/Dashboard";
import Sidebar from "./components/nav/Sidebar";
import CoursesOverview from "./components/dashboard/courses/CoursesOverview";
import {CreateCourse} from "./components/dashboard/courses/CreateCourse";
import {ApplicantCourseSelector} from "./components/dashboard/applicants/ApplicantCourseSelector";
import {ApplicantsOverview} from "./components/dashboard/applicants/ApplicantsOverview";
import UserOverview from "./components/dashboard/users/UserOverview";
import {CreateUser} from "./components/dashboard/users/CreateUser";
import {WebNavBar} from "./components/nav/WebNavBar";
import {Upload} from "./components/utils/upload";
import {LandingPage} from "./components/landingpage/LandingPage";
import {Application} from "./components/application/Application";
import {ErrorContext, MessageContext, WidthContext} from "./contexts/Contexts";
import {ClearIcon} from "@mui/x-date-pickers";
import {DelphiAPIClient} from "./actions/axios-instance";
import {Info} from "@mui/icons-material";

const theme = extendTheme({
    fontFamily: {
        display: 'Open Sans, var(--joy-fontFamily-fallback)',
        body: 'Open Sans, var(--joy-fontFamily-fallback)',

    },
});

function App() {
    const [user, setUser] = useState({})
    const [width, setWidth] = useState(window.innerWidth)
    const [open, setOpen] = useState(false)
    const [messageOpen, setMessageOpen] = useState(false)
    const [error, setError] = useState("")
    const [message, setMessage] = useState({text: "", color: ""})
    useEffect(() => {
        if (!window.location.href.includes("/login") && window.location.pathname !== "/")
            getUser(setUser)
    }, []);

    useEffect(() => {
        if (error)
            setOpen(true)
        if (message.text && message.color)
            setMessageOpen(true)
    }, [error, message])


    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth)
        }
        window.addEventListener("resize", handleResize, false);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        DelphiAPIClient.interceptors.response.use(function (response) {
            if (response && response.data && response.data.hasOwnProperty("error")) {
                setError(response.data.error)
            } else {
                return response
            }
        }, function (error) {
            if (error.response?.status === 401) {
                window.location.href = "/login?ref=" + window.location.pathname
            } else {
                console.log(error.toString())
                setError(`"Er is iets misgegaan probeer het later opnieuw (${error.response?.status})`)
            }
            // return Promise.reject(error)
        })
    }, [setError])

    const teacherPages = [
        {path: "/overzicht", component: Home},
        {path: "/scholen", component: Schools},
        {path: "/upload", component: Upload},
        {path: "/school/:schoolId", component: School},
        {path: "/lessen", component: Courses},
        {path: "/les/:courseId", component: Course},
        {path: "/uitnodiging/:courseId", component: Application},
        {path: "/profiel", component: Profile},
        {path: "/profiel/:initialTab", component: Profile},
    ]

    const adminPages = [
        {path: "/dashboard", component: Dashboard},
        {path: "/dashboard/courses/overview", component: CoursesOverview},
        {path: "/dashboard/courses/create/:courseId", component: CreateCourse},
        {path: "/dashboard/courses/create", component: CreateCourse},
        {path: "/dashboard/courses/applicants", component: ApplicantCourseSelector},
        {path: "/dashboard/courses/applicants/:courseId", component: ApplicantsOverview},
        {path: "/dashboard/users/overview", component: UserOverview},
        {path: "/dashboard/users/create", component: CreateUser},
        {path: "/dashboard/users/create/:userId", component: CreateUser},
    ]
    return (
        <>
            <Snackbar
                variant="soft"
                color="danger"
                size={"lg"}
                open={open}
                autoHideDuration={3000}
                onClose={() => {
                    setOpen(false)
                    setError("")
                }}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                startDecorator={<ClearIcon/>}
            >
                {error}
            </Snackbar>

            <Snackbar
                variant="outlined"
                color={message.color}
                size={"lg"}
                open={messageOpen}
                autoHideDuration={3000}
                onClose={() => {
                    setMessageOpen(false)
                    setMessage({text: "", color: ""})
                }}
                anchorOrigin={{vertical: width < 900 ? "top" : 'bottom', horizontal: 'center'}}
                startDecorator={<Info/>}
            >
                {message.text}
            </Snackbar>
            <CssVarsProvider theme={theme}>
                <BrowserRouter>
                    <ErrorContext.Provider value={{setError: setError, error: error}}>
                        <MessageContext.Provider value={{setMessage: setMessage, message: message}}>
                            <WidthContext.Provider value={{width}}>

                                {!window.location.href.includes("/login") && window.location.pathname !== "/" && user && user.groups && user.groups.includes("Teacher") && !(width < 768 && window.location.href.includes("/les/")) &&
                                    (width < 900 ? <MobileNavBar/> : <WebNavBar/>)}
                                {!window.location.href.includes("/login") && window.location.pathname !== "/" && user && user.groups && user.groups.includes("Administrator") &&
                                    <Sidebar user={user}/>}

                                <Routes>
                                    <Route path={"/login"} Component={Login}/>
                                    <Route path={"/"} Component={LandingPage}/>
                                    {user && user.groups && user.groups.includes("Teacher") &&
                                        teacherPages.map(route => {
                                            return <Route path={route.path} Component={route.component}/>
                                        })
                                    }
                                    {user && user.groups && user.groups.includes("Administrator") &&
                                        adminPages.map(route => {
                                            return <Route path={route.path} Component={route.component}/>
                                        })
                                    }

                                </Routes>
                            </WidthContext.Provider>
                        </MessageContext.Provider>
                    </ErrorContext.Provider>

                </BrowserRouter>
            </CssVarsProvider>
        </>
    );
}

export default App;
