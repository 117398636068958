import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import {Link} from "react-router-dom";

export const SchoolProfileCard = ({school}) => {
    return (

        <Card
            orientation={"horizontal"}
            sx={{
                width: {xs: '90%', md: '70%'}, padding: 0,
                flexWrap: {xs: 'wrap', md: "nowrap"},

            }}
        >
            <AspectRatio flex ratio="1" maxHeight={182} sx={{minWidth: 250}}>
                <img
                    style={{objectFit: "cover"}}
                    src={school.school_picture}
                    loading="lazy"
                    alt=""
                />
            </AspectRatio>
            <CardContent sx={{p: 2}}>
                <Typography level="body-sm" fontWeight="lg" textColor="text.tertiary">
                    {school.type}
                </Typography>
                <Typography fontSize="xl" fontWeight="lg">
                    {school.name}
                </Typography>

                <Typography level="body-sm">
                    {school.description}
                </Typography>
                <a href={`https://www.google.com/maps?q=${school.street_name}+${school.house_number}+${school.postal_code}+${school.city}`} target="_blank">
                    <Typography level={"body-sm"}>
                        <b><i>{school.street_name} {school.house_number}</i></b>
                    </Typography>
                    <Typography level={"body-sm"}>
                        <b><i>{school.postal_code} {school.city}</i></b>
                    </Typography>
                </a>
                <Sheet
                    sx={{
                        bgcolor: 'background.level1',
                        borderRadius: 'sm',
                        p: 1.5,
                        my: 1.5,
                        display: 'flex',

                        gap: 2,
                        '& > div': {flex: 1},
                    }}
                >
                    <div>
                        <Typography level="body-xs" fontWeight="lg">
                            Lessen
                        </Typography>
                        <Typography fontWeight="lg">{school.total_courses}</Typography>
                    </div>
                    <div >
                        <Typography level="body-xs" fontWeight="lg">
                            Open lessen
                        </Typography>
                        <Typography fontWeight="lg">{school.open_courses}</Typography>
                    </div>

                </Sheet>

                <Button variant="solid" onClick={() => {
                    window.open("mailto:" + school.email, "_blank")
                }} color="primary" disabled={!school.email}>
                    Contact
                </Button>
            </CardContent>
        </Card>
    );
}