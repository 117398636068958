import "./nav.css"
import {Avatar, Divider, Sheet, Typography} from "@mui/joy";
import {AccountBox, Explore, Home, MenuBook, School} from "@mui/icons-material";
import {Link} from "react-router-dom";

export const MobileNavBar = () => {
    return <Sheet className={"mobile-nav-container"} style={{backgroundColor:"#272D2D"}}>
        <Link to={"/overzicht"} className={"mobile-nav-item"}>
            <Explore className={"mobile-nav-icon"}/>
            <Typography level={"body"}>Ontdek</Typography>
        </Link>
        <Divider orientation={"vertical"}/>

        <Link className={"mobile-nav-item"} to={"/scholen"}>
            <School className={"mobile-nav-icon"}/>
            <Typography level={"body"}>Scholen</Typography>
        </Link>
        <Divider orientation={"vertical"}/>
        <Link className={"mobile-nav-item"} to={"/lessen"}>
            <MenuBook className={"mobile-nav-icon"}/>
            <Typography level={"body"}>Lessen</Typography>
        </Link>
        <Divider orientation={"vertical"}/>

        <Link to={"/profiel"} className={"mobile-nav-item"}>
            <AccountBox className={"mobile-nav-icon"}/>
            <Typography level={"body"}>Ik</Typography>
        </Link>
    </Sheet>
}
